import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Layout from 'components/Layout';
import { Container, Row, Col } from 'react-bootstrap';
import divider from 'assets/images/path-3.png';
import CTA from '../components/CTA';
import Img from 'gatsby-image';

import Group24 from '../assets/images/topics/group-24.inline.svg';
import Group25 from '../assets/images/topics/group-25.inline.svg';
import Group27 from '../assets/images/topics/group-27.inline.svg';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  let introBg = frontmatter.introBg.childImageSharp.fixed;
  let speakerImg = frontmatter.speakerImg.childImageSharp.fixed;

  return (
    <Layout>
      <Helmet>
        <title>EY - The enlightenment session</title>
      </Helmet>
      <div className='topicTemplate'>
        <Container>
          <div className='topicIntro'>
            <div className='banner'>
              <Img fixed={introBg} />
            </div>
            <div className='text'>
              <Row>
                <Col>
                  <h1>
                    {frontmatter.title}{' '}
                    {frontmatter.titlePrimary && (
                      <span>{frontmatter.titlePrimary}</span>
                    )}
                  </h1>
                  <h3>{frontmatter.subTitle}</h3>
                  <div
                    className='introText'
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </Col>
                <Col />
              </Row>
            </div>
          </div>
          <div className='divider'>
            <img src={divider} alt='divider' />
          </div>
          <div className='speaker'>
            <div>
              <Img fixed={speakerImg} />
            </div>
            <div className='speakerIntro'>
              <h1>{frontmatter.speaker}</h1>
              <h4>
                {frontmatter.job} at <strong>{frontmatter.company}</strong>
              </h4>
              <div
                className='introText'
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
          <div className='topicExpect'>
            <h1>
              What you <span>can expect?</span>
            </h1>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer.
            </p>
            <div className='expectWrapper'>
              <div className='expectItem'>
                <Group24 />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
              </div>
              <div className='expectItem'>
                <Group27 />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
              </div>
              <div className='expectItem'>
                <Group25 />
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting.
                </p>
              </div>
            </div>
          </div>
          <CTA />
        </Container>
      </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        titlePrimary
        subTitle
        speaker
        job
        company
        introBg {
          childImageSharp {
            fixed(width: 965) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        speakerImg {
          childImageSharp {
            fixed(width: 621) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
