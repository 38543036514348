import React from 'react';
import { Container } from 'react-bootstrap';

const CTA = () => {
  return (
    <div className='CTA'>
      <Container>
        <h2>
          The <span>enlightenment</span> session
        </h2>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has <br />
          been the industry's standard dummy text ever since the 1500s, when an
          unknown printer.
        </p>
        <a
          href='mailto:info@vodw.be?subject=Contact me about the enlightenment sessions'
          className='button'
        >
          Get involved
        </a>
      </Container>
    </div>
  );
};

export default CTA;
